<template>
  <div class="contents allVod">
    <h1 style="height: 50px;line-height: 50px;margin-top:50px;">{{ $t("site-control-device-sub-drone-station") }}
    </h1>
    <div class="content-row m-b-20">
      <div class="map-content">
        <div class="map">
          <Map ref="map" style="width: 100%; height: 100%; border-radius:5px;" :loadMapName="loadMap"/>
          <MapButtonComponent ref="buttonMap"/>
          <span v-if="locationSize == 0"
                style="position:absolute; z-index:4; top: 50%; left: 50%; transform: translate(-50%, -50%); height:auto; font-size: 2rem; font-weight: bold; color: white; background: rgba(0,0,0,0.8); padding: 2px 8px; ">
                        {{ $t("popup-location-not-found") }}
                    </span>
        </div>
        <div class="time-list content-box">
          <div class="time-list-title">
            <h2>{{ $t("station-schedule-list-title") }}</h2>
            <select v-model="timeLineSearchData.selectType" @change="timeLineChangeStatus">
              <option v-for="item in timeLineSearchData.searchTypes" :value="item.value"
                      :key="item.value">{{ item.name }}</option>
            </select>
          </div>
          <ul ref="timeLineRoot">
            <li v-for="item in timeLineList" :key="item.localTime">
              <div class="time-list-row">
                                <span class="time-list-mark"
                                      :class="checkTime == item.localTime.substring(0, 5) ? 'schedule-on' : 'schedule-off'"></span>
                <p>{{ item.localTime.substring(0, 5) }}</p>
                <h4>ㆍㆍㆍ</h4>
                <h3>{{ (item.localTime.substring(0, 5) > checkTime && checkScheduleTimeLineRunCount(item) === 0) ? $t("station-schedule-state-waiting") :
                    (  checkScheduleTimeLineRunCount(item) > 0 || item.localTime.substring(0, 5) === checkTime) ? $t("station-schedule-state-working") : $t("station-schedule-state-end") }}</h3>
              </div>
              <div class="station-number-mark">
                <span v-for="(station, idx) in item.items" :class="checkScheduleStationIsRun(station) !== true ?  '' : checkDroneIsRTL(station.stationDeviceId ) !== true ?  'mission-on' : 'rth-on' " :key="idx + station.stationDeviceId">{{ station.stationDeviceTag }}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="info-content">
        <div class="info-content-row">
          <div class="weather-box content-box">
            <div class="weather-box-title">
              <h2>{{ $t("site-view-weather-title") }}
                <a @click="getWeatherList" style="line-height: 1.2rem;"><!--새로고침 버튼-->
                  <img src="@/assets/images/icon_svg/ic_refresh.svg" alt="icon"
                       style="width: 1.5rem; margin-left: 10px;" />
                </a>
              </h2>
              <div>
                <p>
                  {{ $t("site-view-weather-update") }} : <span>{{ weatherUpdate == "" || weatherUpdate ==
                undefined ? " - " : moment(weatherUpdate).format("YYYY.MM.DD HH:mm") }}</span>
                </p>
              </div>
            </div>
            <div class="flexB">
              <div class="weatherWrap flex">
                <div class="cover" v-for="(data, i) in weatherList" :key="i">
                  <h2>
                    {{ data.weatherEventDataId.fcstDateTime.substr(8, 2) + $t('weather-on-time') }}
                  </h2>
                  <img :src="require(`@/assets/images/icon_svg/${imgBinding(data)}.svg`)"
                       alt="icon" />
                  <p>{{ data.temperatures == undefined ? '-' : data.temperatures.split(" ")[0]
                    }}&#8451;</p>

                  <img v-if="data.windDirection != undefined"
                       src="@/assets/images/icon_svg/ic_w_wind.svg" alt="icon"
                       :style="`transform: rotate(${data.windDirection.replace(' ', '')})`" />
                  <img v-if="data.windDirection == undefined"
                       src="@/assets/images/icon_svg/ic-minus.svg" />

                  <p>
                    {{ data.windDirection == undefined ? '-' :
                      convertWindDirection(data.windDirection.split(" ")[0]) }}<br />
                    {{ data.windDirection == undefined ? '-' : data.windSpeed }}
                  </p>
                  <img :src="require(`@/assets/images/icon_svg/${humidityBinding(data.humidity)}.svg`)"
                       alt="icon" />
                  <!-- 습도 img 파일명 /  humidity_1 ~ humidity_5  -->
                  <p>{{ data.humidity == undefined ? '-' : data.humidity }}</p>
                  <!--<p>{{ data.rain == undefined || data.rain == "강수없음" ? '0mm' : data.rain }}</p>-->
                </div>
              </div>
            </div>
          </div>
          <div class="noTam-box content-box">
            <h2>NOTAM</h2>
            <h4>{{ $t("station-notam-comment") }}</h4>
          </div>
        </div>
        <div class="station-controller content-box">
          <div class="station-controller-title">
            <h4><img src="@/assets/images/top_menu_icon_c4.png" />{{ $t("station-control-title") }}</h4>
          </div>
          <div class="station-controller-btn">
            <button @click="selectAll">{{ $t("btn-all-select") }}</button>
            <button @click="deselectAll">{{ $t("btn-all-release") }}</button>
            <button class="start-btn" @click="stationScheduleChangeAll(true)">{{ $t("btn-all-start") }}</button>
            <button class="stop-btn" @click="stationScheduleChangeAll(false)">{{ $t("btn-all-stop") }}</button>
          </div>
        </div>
      </div>`
    </div>
    <div class="content-row">
      <div class="station-list-row">
        <div v-for="(n, index) in 12" :key="index" class="station-box">
          <div class="station-vod">
            <div v-if="stationDeviceListMap[index] !== undefined && stationDeviceListMap[index].device.modeCode !== null && stationDeviceListMap[index].modeCode !== EDockModeCode.Disconnected.code ">
              <template v-if="stationDeviceListMap[index] !== undefined && stationDeviceListMap[index].device.modeCode !== null && stationDeviceListMap[index].modeCode !== EDockModeCode.Disconnected.code ">
                <Video
                    :ref="`video_${channelListMap[stationDeviceListMap[index].device.managerData.referenceDeviceId].channelId}`"
                    v-if="channelListMap[stationDeviceListMap[index].device.managerData.referenceDeviceId] != undefined"
                    v-show="channelListMap[stationDeviceListMap[index].device.managerData.referenceDeviceId].isLoad == true"
                    :src="getHlsUrl(channelListMap[stationDeviceListMap[index].device.managerData.referenceDeviceId])"
                    :isAuto="true"
                    :isVod="false"
                    :isControls="false"
                    :playerId="channelListMap[stationDeviceListMap[index].device.managerData.referenceDeviceId].channelId"
                    :isShowLiveText = "false"
                    :channelIndex="index"
                    :isShowVolum="false"
                    :isShowFullScreen="false"
                    :deviceType="channelListMap[stationDeviceListMap[index].device.managerData.referenceDeviceId].assignedDeviceType"
                    @playerRemoveId="playerRemove"
                    @isLoadedmetadata="playerLoadData"/>
              </template>
            </div>
            <input v-if="stationDeviceListMap[index] != undefined" style="position: absolute; border-color: #b9e0ff;top: 5px;left: 5px;z-index: 100;" type="checkbox" :value="stationDeviceListMap[index] != null && stationDeviceListMap[index].device.stationDeviceId" v-model="checkedStations" />
            <div class="play-off" v-if="stationDeviceListMap[index] == null || stationDeviceListMap[index].device.modeCode == null || stationDeviceListMap[index].device.modeCode == EDockModeCode.Disconnected.code">
              <img src="@/assets/images/play-off-icon.png" alt="icon" />
              <h2>{{ $t("monitoring-view-no-signal") }}</h2>
            </div>
          </div>
          <div class="station-info">
            <div class="station-info-detail">
              <div class="station-title">
                <h4 class="m-b-5">{{ stationDeviceListMap[index] == null || stationDeviceListMap[index].device.managerData == null ||stationDeviceListMap[index].device.managerData.tag == null ? '-' :
                    stationDeviceListMap[index].device.managerData.tag + $t('station-number') +
                    stationDeviceListMap[index].device.deviceName }}</h4>
                <p><img src="@/assets/images/icon_svg/ic_b_drone.png" alt="icon" />{{ stationDeviceListMap[index] == null || stationDeviceListMap[index].children == null ? '-' : stationDeviceListMap[index].children.deviceName }}</p>
              </div>
              <div class="station-situation">
                <div class="situation-row m-b-5">
                  <span :class="stationDeviceListMap[index] == null || stationDeviceListMap[index].device.modeCode == null || stationDeviceListMap[index].device.modeCode == EDockModeCode.Disconnected.code ? 'station-off' : 'station-on' "></span>
                  <p>{{ stationDeviceListMap[index] == null || stationDeviceListMap[index].device.modeCode == null ? EDockModeCode.getData(EDockModeCode.Disconnected).showName : EDockModeCode.getData(stationDeviceListMap[index].device.modeCode).showName  }}</p>
                </div>
                <div class="situation-row">
                  <span :class="stationDeviceListMap[index] == null || stationDeviceListMap[index].children == null ||  stationDeviceListMap[index].children.modeCode == null || stationDeviceListMap[index].children.modeCode == EModeCode.Disconnected.code ? 'station-off' : stationDeviceListMap[index].children.modeCode == EModeCode.Return_To_Home.code || stationDeviceListMap[index].children.modeCode == EModeCode.Landing.code ? 'station-rtl' : 'station-on' "></span>
                  <p>{{ stationDeviceListMap[index] == null || stationDeviceListMap[index].children == null || stationDeviceListMap[index].children.modeCode == null ? EModeCode.getData(EModeCode.Disconnected).showName : EModeCode.getData(stationDeviceListMap[index].children.modeCode).showName  }}</p>
                </div>
              </div>
            </div>
            <div class="station-bottom">
              <button  @click="stationOpen(stationDeviceListMap[index] === undefined ? null : stationDeviceListMap[index].device.stationDeviceId)">{{ $t("station-detail-btn") }}</button>
              <div class="scb-btn">
                <button v-if="stationDeviceListMap[index] != null" @click="stationScheduleChange(stationDeviceListMap[index].device.stationDeviceId, stationDeviceListMap[index].device.managerData.isScheduleRun !== true)">
                  <img v-if="stationDeviceListMap[index].device.managerData.isScheduleRun !== true" src="@/assets/images/ic_stin_pause.png" alt="icon" />
                  <img v-if="stationDeviceListMap[index].device.managerData.isScheduleRun === true" src="@/assets/images/ic_stin_start.png" alt="icon" />
                </button>
              </div>
            </div>
            <div class="station-battery">
              <div class="station-battery-content">
                <div class="station-battery-box">
                  <div class="battery-volume" :style="[batteryStyle[index]]"></div>
                </div>
                <h4>{{ stationDeviceListMap[index] === undefined || stationDeviceListMap[index].children.batteryPercent === null || stationDeviceListMap[index].children.batteryPercent === undefined ? '0' : stationDeviceListMap[index].children.batteryPercent }} <span>%</span></h4>
              </div>

              <h4>
                <img src="@/assets/images/ic_battery_count.png" alt="icon" />
                <span>{{ stationDeviceListMap[index] === undefined || stationDeviceListMap[index].children.batteryLifeCycle === null || stationDeviceListMap[index].children.batteryLifeCycle === undefined ? '-' :stationDeviceListMap[index].children.batteryLifeCycle }} </span>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.title {
  display: none;
}

/* .lnb {
    display: none;
} */

.m-b-20 {
  margin-bottom: 20px;
}

.m-b-5 {
  margin-bottom: 5px;
}

.content-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-box {
  background: #fff;
  padding: 15px;
  border-radius: 5px;
}

.map-content {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 10px;
}

.map-content>.map {
  width: 65%;
  height: 350px;
}

.time-list {
  width: calc(35% - 20px);
  height: 350px;
}

.time-list>.time-list-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.time-list>.time-list-title>h2 {
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 0px;
}

.time-list>.time-list-title>select {
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 0px;
  font-weight: 400;
  width: 120px;
  border-radius: 5px;
}

.time-list>ul {
  max-height: 270px;
  height: 270px;
  overflow-y: scroll;
}

.time-list>ul>li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.time-list::-webkit-scrollbar {
  width: 5px;
}

.time-list::-webkit-scrollbar-thumb {
  background-color: #969696;
  border-radius: 4px;
}

.time-list-row {
  display: flex;
  align-items: center;
}

.time-list-row>.time-list-mark {
  width: 13px;
  height: 13px;
  background: #ddd;
  border-radius: 10px;
  margin-right: 5px;
}

.time-list-row>.time-list-mark.schedule-on {
  background: #32e783;
}

.time-list-row>p {
  font-size: 1.2rem;
  width: 35px;
}

.time-list-row>h3 {
  font-size: 1.2rem;
  font-weight: 400;
}

.time-list-row>h4 {
  font-size: 1.2rem;
  color: #969696;
  margin: 0 5px 0 0;
  letter-spacing: -5px;
}

.station-number-mark {
  padding-right: 5px;
  display: flex;
  align-items: center;
}

.station-number-mark>span {
  width: 20px;
  height: 20px;
  margin-left: 3px;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  color: #000;
  background: #eee;
}

.station-number-mark>span.mission-on {
  background: #0ECE4E;
  color: #fff;
}

.station-number-mark>span.rth-on {
  background: #FF4747;
  color: #fff;
}

.info-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  margin-left: 10px;
}

.info-content-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 270px;
  margin-bottom: 20px;
}

.weather-box {
  width: 60%;
  height: 100%;
}

.weather-box-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.weather-box-title>h2 {
  font-size: 1.4rem;
  margin: 0;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.noTam-box {
  width: calc(40% - 20px);
  height: 100%;
}

.noTam-box>h2 {
  font-size: 1.4rem;
  margin: 0;
  font-weight: bold;
}

.noTam-box>h4 {
  font-size: 1.2rem;
  color: #969696;
  font-weight: 400;
  text-align: center;
  margin-top: 100px;
}

.station-controller {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #0080ff;
}

.station-controller-title {
  display: flex;
  align-items: center;
}

.station-controller-title>h4 {
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  color: #fff;
}

.station-controller-title>h4>img {
  width: 20px;
  margin-right: 10px;
}

.station-controller-title>p {
  font-size: 1.4rem;
  color: #ffffffcc;
  margin-left: 30px;
}

.station-controller-btn {
  display: flex;
  align-items: center;
}

.station-controller-btn>button {
  border: 1px solid #fff;
  border-radius: 5px;
  margin-left: 5px;
  padding: 0 15px;
  color: #fff;
}

.station-controller-btn>button.stop-btn {
  background: #ff5151;
  color: #fff;
  border: 0;
  margin-left: 5px;
}

.station-controller-btn>button.start-btn {
  background: #0848B5;
  color: #fff;
  border: 0;
  margin-left: 15px;
}

.station-list-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.station-box {
  width: 15.6666%;
  margin-bottom: 20px;
}

.station-vod {
  position: relative;
  background: #eee;
  width: 100%;
  height: 125px;
  border-radius: 5px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  overflow: hidden;
}

.station-vod > .play-on > .video-player > .video-js  {
  padding-top: 125px;
}

.station-vod>.play-on {
  position: absolute;
  width: 100%;
  height: 100%;
}

.station-vod>.play-off {
  width: 100%;
  height: 100%;
  background: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.station-vod>.play-off>img {
  width: 25px;
  margin-bottom: 10px;
}

.station-vod>.play-off>h2 {
  color: #fff;
  font-size: 1.2rem;
}

.station-info {
  background: #fff;
  padding: 8px 10px;
}

.station-info>.station-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 5px;
}

.station-info>.station-bottom>button {
  border: 0;
  padding: 0;
  height: auto;
  color: #2f5ded;
}

.station-info>.station-bottom>.scb-btn {
  border: 0;
  padding: 0;
  height: auto;
}

.station-info>.station-bottom>.scb-btn>button {
  border: 0;
  width: 75px;
  margin: 0;
  height: 18px;
  padding: 0;
}

.station-info>.station-bottom>.scb-btn>button>img {
  width: 100%;
}

.station-info-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
  border-bottom: 1px solid #ddd;
}

.station-title>h4 {
  font-size: 1.4rem;
  font-weight: 600;
}

.station-title>p {
  font-size: 1.2rem;
  color: #969696;
  display: flex;
  align-items: center;
}

.station-title>p>img {
  width: 17px;
  margin-right: 5px;
}

.situation-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.situation-row>span {
  width: 10px;
  height: 10px;
  background: #eee;
  border-radius: 10px;
  margin-right: 10px;
}

.situation-row>p {
  font-size: 1.2rem;
  color: #969696;
}

.situation-row>span.station-on {
  background: #32e783;
}
.situation-row>span.station-rtl {
  background: #FF4747;
}
.weatherWrap{
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  margin-top: 15px;
}
.weatherWrap > .cover{
  text-align: center;
}
.battery-count{position: absolute;top: 10px;right: 10px;background: #101010ee;padding: 1px 5px;}
.battery-count > img{height: 10px;margin-right: 5px;}
.battery-count > span{color: #fff;font-size: 1.2rem;}

.station-battery{display: flex;margin-top: 10px;}
.station-battery > .station-battery-content{display: flex;}
.station-battery > .station-battery-content > .station-battery-box{position: relative;height: 18px;width: 35px;background: #eee;border-radius: 3px;margin-right: 5px;}
.station-battery > .station-battery-content > .station-battery-box > .battery-volume{position: absolute;top: 0;left: 0;height: 18px;width: 0%;background: #4f555e;border-radius: 3px;}

.station-battery > .station-battery-content > h4{font-size: 1.3rem;font-weight: 500;}
.station-battery > .station-battery-content > h4 > span{font-size: 1.2rem;}

.station-battery > h4{margin-left: 15px; display: flex;align-items: center;}
.station-battery > h4 > img{width: 10px;margin-right: 5px;}
.station-battery > h4 > span{font-size: 1.3rem;font-weight: 500;}
</style>


<script>
import moment from "moment";
import {getHlsUrl} from "@/components/common/LiveUtils";
import Map from "@/components/map/Map";
import MapDataType from "@/components/map/enum/MapDataType";
import MapMarkerData from "@/components/map/data/MapMarkerData";
import { fetchChannelList } from "@/api/channel";

import { fetchEvent } from "@/api/event";
import { fetchPointList, getPointTypeName } from "@/api/point";

import { mapState } from "vuex";
import { stationPopup } from "@/util/popup";
import { ko } from "vuejs-datepicker/dist/locale";

import windDirection from "@/util/windDirection";
import Video from "@/components/video/videoPlayer";
stationPopup

//스테이션 API
import { fetchStationDeviceDockList } from "@/api/station/stationDevice";
import { updateStationDeviceManagerScheduleChange, updateStationDeviceManagerScheduleChangeList } from "@/api/station/stationDeviceManager";
import { fetchStationScheduleTimeline, getScheduleTypes } from "@/api/station/stationSchedule";
import  UseConnectWebSocket from "@/station/websocket/use-connect-websocket";
import { messageHandler } from "@/station/websocket/message-handler-websocket";
import { EDockModeCode, EModeCode } from '@/station/type/device'
import { fetchWeatherEvent, imgBinding, humidityBinding } from "@/api/weather";
import { fetchStationMissionJob} from "@/api/station/stationMissionJob";
import MapButtonComponent from "@/components/map/MapButtonComponent.vue";


export default {
  components: { Map,
    MapButtonComponent,
    Video
  },
  name: "Main",
  props: {
    eventId: {
      type: String,
      default: "",
    },
    address: {
      type: String,
      default: "",
    },

  },
  data() {
    return {
      EDockModeCode : EDockModeCode,
      EModeCode: EModeCode,
      //index: 0,
      moment: moment,
      loadMap : 'kakao',
      viewSrc: "",

      isdrModelShow:true,
      eventInfo : null,
      contentsId: null,
      location: [],
      lat : null,
      lng : null,
      locationSize : null,
      ko: ko,
      startYmd: null,
      endYmd: null,
      currentPage: 0,
      size: 10,
      keywordType: "all",
      keyword: "",
      total: 0,

      checkedStations: [],

      channelList : [],

      //station
      stationDeviceList: [],
      stationTempDeviceList: [],

      timeLineSearchData: {
        selectType: null,
        searchTypes: getScheduleTypes(),
      },
      timeLineList: [],
      updateClockTimer: null,
      checkTime: moment().format("HH:mm"),

      weatherList: null,
      weatherUpdate: null,

      useConnectWebSocket : null,
      thumbnail : {},
      tempBase64 : null,
      tempTimestamp : null,
      md5 : null,

      batteryPercentage: 0, // 초기 퍼센트 값
    };
  },
  computed: {

    ...mapState({
      currentLang: state => state.store.currentLang,
      userType: state => state.store.userType,
      accountId: state => state.store.accountId,
      dockInfo: state => state.station.deviceDockInfo,
      droneInfo: state => state.station.deviceDroneInfo,
      deviceThumbnailInfo: state => state.station.deviceThumbnailInfo,
    }),
    stationDeviceListMap() {
      // stationDeviceList를 인덱스를 키로 하는 객체로 변환
      return this.stationDeviceList.reduce((map, item, index) => {
        map[index] = item;
        return map;
      }, {});
    },
    channelListMap() {
      // stationDeviceList를 인덱스를 키로 하는 객체로 변환
      return this.channelList.reduce((map, item) => {
        map[item.assignedDeviceId] = item;
        return map;
      }, {});
    },
    batteryStyle() {
      // 스타일 바인딩으로 width와 background-color를 동적으로 설정
      return this.stationDeviceList.reduce((map, item, index) => {
        var batteryPercentage = 0
        if(item.children.batteryPercent !== null || item.children.batteryPercent !== undefined) {
          batteryPercentage = item.children.batteryPercent
        }
        map[index] = {
          width: batteryPercentage + '%',
          backgroundColor: batteryPercentage <= 20 ? '#ff4d4f' : '#a4d868', // 20% 이하일 경우 빨간색
          transition: 'width 0.3s ease, background-color 0.3s ease',
        }
        return map;
      }, {} )
    },
  },
  watch : {
    currentLang(value){
      this.initMapData(value);
    },
    dockInfo : {
      handler(info){
        let keys = Object.keys(info)
        for(let i = 0; i < this.stationDeviceList.length; i++) {
          let stationDevice = this.stationDeviceList[i]
          let findKeyIndex = keys.findIndex((key) => key === stationDevice.device.deviceSerial)
          if(findKeyIndex === -1) {
            let channelIndex = this.channelList.findIndex((channel) => channel.assignedDeviceId === stationDevice.device.managerData.referenceDeviceId)
            if(channelIndex !== -1) {
              this.channelList.splice(channelIndex, 1)
            }
            continue
          }

          let dockInfo = info[stationDevice.device.deviceSerial]
          if(dockInfo.base === undefined ) {
            continue
          }


          if(dockInfo.base.droneChargeState !== undefined && dockInfo.base.droneChargeState.capacityPercent !== stationDevice.children.batteryPercent) {
            stationDevice.children.batteryPercent = dockInfo.base.droneChargeState.capacityPercent
            this.$set(this.stationDeviceList, i, stationDevice);
          }

          if(dockInfo.base.latitude != 0 && dockInfo.base.longitude != 0 && stationDevice.device.latitude != dockInfo.base.latitude && stationDevice.device.longitude != dockInfo.base.longitude) {
            stationDevice.device.managerData.lat = dockInfo.base.latitude;
            stationDevice.device.managerData.lng = dockInfo.base.longitude;
            this.drawStationMarker(stationDevice);
            this.$set(this.stationDeviceList, i, stationDevice);
          }

          let channelFindIndex = this.channelList.findIndex((channel) => channel.assignedDeviceId === stationDevice.device.managerData.referenceDeviceId)
          if(channelFindIndex === -1) {
            this.getChannelStationList();
          }

          if(dockInfo.base.modeCode === EDockModeCode.Working.code) {
            this.getStationMissionJob(stationDevice.device.stationDeviceId)
          } else {
            this.setChangeScheduleId(stationDevice.device.stationDeviceId, stationDevice.device.scheduleId, false)
          }

          if(dockInfo.base.modeCode !== stationDevice.device.modeCode) {
            stationDevice.device.modeCode = dockInfo.base.modeCode;
            this.$set(this.stationDeviceList, i, stationDevice);
          }
        }
      },
      deep: true
    },
    droneInfo : {
      handler(info){
        let keys = Object.keys(info)
        for(let i = 0; i < this.stationDeviceList.length; i++) {
          let stationDevice = this.stationDeviceList[i]
          let findKeyIndex = keys.findIndex((key) => stationDevice.children !== null && key === stationDevice.children.deviceSerial)
          if(findKeyIndex === -1) {
            continue
          }

          let droneInfo = info[stationDevice.children.deviceSerial];

          if(droneInfo.modeCode !== stationDevice.children.modeCode) {
            stationDevice.children.modeCode = droneInfo.modeCode;
            this.$set(this.stationDeviceList, i, stationDevice);
          }

          if(droneInfo.modeCode === EModeCode.Disconnected.code) {
            stationDevice.children.batteryLifeCycle = null
            this.$set(this.stationDeviceList, i, stationDevice);
          } else {
            let batteryLifeCycle = null
            droneInfo.battery.batteries.forEach((battery) => {
              batteryLifeCycle = battery.loopTimes
              if(batteryLifeCycle != null) {
                batteryLifeCycle += "/"
              }
            })
            if(batteryLifeCycle != null) {
              batteryLifeCycle = batteryLifeCycle.substring(0, batteryLifeCycle.length - 1)
              stationDevice.children.batteryLifeCycle = batteryLifeCycle
              this.$set(this.stationDeviceList, i, stationDevice);
            }
          }


        }
      },
      deep: true
    },
  },
  mounted() {
    // await this.getSDList();
    this.getEvent();
    this.initMapData(this.$store.state.store.currentLang);
    this.initButtonMap()

    this.updateClockTimer = setInterval(() => {
      this.updateClock()
    }, 1000);

    //statio api
    this.getScheduleTimeLine();
    this.updateScheduleTimer = setInterval(() => {
      let diff = moment(this.updateScheduleTime).diff(moment())
      let add = Math.abs(diff)/1000
      if(add> 30) {
        this.getScheduleTimeLine();
      }
    }, 1000);

    this.getStationDeviceDockList();
    this.useConnectWebSocket.onWebSocketMounted();
    this.$refs.map.onSetZoomLevel(13)
  },
  created() {
    this.$store.dispatch("store/SET_NAVBAR", { nav: 9, sub: 2 });
    this.useConnectWebSocket = new UseConnectWebSocket();
    this.useConnectWebSocket.onConnectWebSocket(messageHandler, this.eventId);
    this.getWeatherList()
  },
  methods: {
    getHlsUrl,
    humidityBinding,
    imgBinding,
    playerRemove(id){
      let findIndex = this.channelList.findIndex(item => item.channelId == id);
      if(findIndex != -1){
        this.channelList.splice(findIndex,1);
      }
    },
    initButtonMap() {
      this.$refs.buttonMap.showMapTypeChange()
      this.$refs.buttonMap.showMapCenter()
    },
    getWeatherList() {
      this.weatherList = []
      fetchWeatherEvent(this.eventId).then((res) => {
        this.weatherList = res.data.data.WeatherEventDataList
        this.weatherUpdate = res.data.data.apiRequestDate;
      })
    },
    convertWindDirection(data) {
      return windDirection[Math.floor((Number(data) + 11.25) / 22.5)];
    },

    updateClock() {
      this.checkTime = moment().format("HH:mm");
    },

    //region 스테이션 API ------------------------------------------------------------
    checkDroneIsRTL(stationDeviceId){
      let findIndex = this.stationDeviceList.findIndex((find) => find.device.stationDeviceId == stationDeviceId )
      if(findIndex < 0) {
        return false
      }
      if(this.stationDeviceList[findIndex].children === null) {
        return false
      }
      return this.stationDeviceList[findIndex].children.modeCode === EModeCode.Return_To_Home.code || this.stationDeviceList[findIndex].children.modeCode === EModeCode.Landing.code;
    },

    checkScheduleStationIsRun(scheduleStation) {
      return scheduleStation.isScheduleRun
    },
    checkScheduleTimeLineRunCount(timeLineItem){
      let filter = timeLineItem.items.filter(item => item.isScheduleRun);
      return filter.length;
    },
    getStationDeviceDockList() {
      let params = {
        eventId: this.eventId
      }
      fetchStationDeviceDockList(params).then((res) => {
        if (res.status == 200 && res.data.result == 0) {
          this.stationDeviceList = res.data.data.content.sort((a, b) => a.device.managerData.tag - b.device.managerData.tag);
          this.stationDeviceList.forEach((item) => {
            if(item.device.managerData.lat != null && item.device.managerData.lng != null) {
              this.drawStationMarker(item);
            }
            item.device.modeCode = null;
            item.children.modeCode = null;
            this.storeDockInfo = this.$store.getters["station/GET_DOCK_INFO"](item.device.deviceSerial);
            this.storeDroneInfo = this.$store.getters["station/GET_DRONE_INFO"](item.children.deviceSerial);
          });
        }
      }).catch((err) => {
        console.log("getStationDeviceDockList")
        console.log("err")
        console.log(err)
      });
    },
    drawStationMarker(stationDeviceInfo) {
      if(stationDeviceInfo == null) {
        return
      }
      if (this.$refs.map === undefined) {
        return
      }

      const mapDataType = MapDataType.ID_SCHEDULE_STATION_NUMBER
      // const index = Number(item.device.managerData.stationDeviceId)
      const mapMarkerData = new MapMarkerData();
      mapMarkerData.id = stationDeviceInfo.device.stationDeviceId;
      mapMarkerData.name = stationDeviceInfo.device.managerData.tag;
      mapMarkerData.isRTL = this.checkDroneIsRTL(mapMarkerData.id)
      let isMain = false;
      for(let i = 0; i < this.timeLineList.length; i++) {
        let item = this.timeLineList[i]
        for(let j = 0; j < item.items.length; j++) {
          let findItem = item.items[j]
          if(findItem.stationDeviceId === mapMarkerData.id && findItem.isScheduleRun) {
            isMain = true
            break;
          }
        }
      }

      mapMarkerData.isMain = isMain
      // mapMarkerData.lng = item.lng;
      // mapMarkerData.lat = item.lat;
      mapMarkerData.lng = stationDeviceInfo.device.managerData.lng;
      mapMarkerData.lat = stationDeviceInfo.device.managerData.lat;

      if (this.$refs.map.onIsMarker(mapDataType, stationDeviceInfo.device.stationDeviceId)) {
        this.$refs.map.onModifyMarkerAt(mapDataType, mapMarkerData);
      } else {
        this.$refs.map.onCreateMarker(mapDataType, mapMarkerData);
      }
    },
    //endregion 스테이션 API ------------------------------------------------------------

    //스케줄러 개벌 업데이트(true - 시작, false - 중지)
    updateStationDeviceScheduleChange(stationDeviceId, isScheduleRun) {
      let params = {
        isScheduleRun: isScheduleRun,
      }
      updateStationDeviceManagerScheduleChange(stationDeviceId, params).then((res) => {
        if (res.status == 200 && res.data.result == 0) {
          this.stationDeviceList.forEach((item) => {
            if (item.device.stationDeviceId == stationDeviceId) {
              item.device.managerData.isScheduleRun = isScheduleRun
            }
          });
          if (isScheduleRun) {
            alert(this.$t('station-alert-start'));
          } else {
            alert(this.$t('station-alert-stop'));
          }
        } else {
          alert(this.$t('station-alert-change-state-failure'));
        }
      }).catch((err) => {
        alert(this.$t('station-alert-change-state-request-failure'));
        console.error(err)
      });
    },

    //스케줄러 전체 업데이트(true - 시작, false - 중지)
    updateStationDeviceScheduleChangeList(stationDeviceIds, isScheduleRun) {
      let params = {
        isScheduleRun: isScheduleRun,
        list: stationDeviceIds
      }

      updateStationDeviceManagerScheduleChangeList(params).then((res) => {
        if (res.status == 200 && res.data.result == 0) {
          if (isScheduleRun) {
            alert(this.$t('station-alert-start'));
          } else {
            alert(this.$t('station-alert-stop'));
          }
          this.deselectAll();
          this.getStationDeviceDockList();
        }
      }).catch((err) => {
        alert(this.$t('station-alert-change-state-request-failure'));
        console.log(err)
      });
    },

    timeLineChangeStatus() {
      this.getScheduleTimeLine();
    },

    getScheduleTimeLine() {
      let beginLocalDate = moment().format("YYYY-MM-DD");
      let endLocalDate = moment().format("YYYY-MM-DD");

      this.updateScheduleTime = Date();
      let params = {
        beginLocalDate: beginLocalDate,
        endLocalDate: endLocalDate,
        scheduleType: this.timeLineSearchData.selectType,
        eventId: this.eventId,
      }
      fetchStationScheduleTimeline(params).then((res) => {
        if (res.status === 200 && res.data.result === 0) {
          if (res.data.data[0].localDate === beginLocalDate) {
            this.timeLineList = []
            let tempList = res.data.data[0].items;
            if (tempList.length > 0) {
              tempList.forEach((item) => {
                item.isScheduleRun = false;
                let findIndex = this.timeLineList.findIndex((x) => x.localDate == item.localDate && x.localTime == item.localTime)
                var pushData = null
                if (findIndex == -1) {
                  var create = {
                    localDate: item.localDate,
                    localTime: item.localTime,
                    items: []
                  }
                  this.timeLineList.push(create)
                  pushData = create.items
                } else {
                  pushData = this.timeLineList[findIndex].items
                }
                pushData.push(item)
              })
            }
            this.setIsScheduleRun()
          }
        }
      });
    },

    setChangeScheduleId(stationDeviceId, scheduleId, isScheduleRun) {
      let findIndex = this.stationDeviceList.findIndex((find) => find.device.stationDeviceId === stationDeviceId)
      if(findIndex > -1) {
        if(isScheduleRun) {
          this.stationDeviceList[findIndex].device.scheduleId = scheduleId
        } else {
          this.stationDeviceList[findIndex].device.scheduleId = null
        }
      }
      this.setIsScheduleRun()
    },
    setIsScheduleRun() {
      let filter = this.stationDeviceList.filter((find) => find.device.scheduleId !== null)
      this.$refs.timeLineRoot.scrollTop = this.$refs.timeLineRoot.scrollHeight;
      if(filter.length === 0) {
        return
      }
      let stationScheduleIds = filter.map((item) => item.device.scheduleId)
      stationScheduleIds.forEach((stationScheduleId) => {
        for(let idx in this.timeLineList) {
          let item = this.timeLineList[idx];
          if(item.items.length > 0) {
            let findItemIndex = item.items.findIndex((find) => find.scheduleId === stationScheduleId)
            if(findItemIndex > -1) {
              item.items[findItemIndex].isScheduleRun = true;
              break;
            }
          }
        }
      })
    },

    async getStationMissionJob(stationDeviceId) {
      let response = await fetchStationMissionJob(stationDeviceId)
      if(response.code !== 200 && response.data.result !== 0) {
        return
      }
      let scheduleId = response.data.data.stationScheduleItemLog.scheduleId
      if(scheduleId == null) {
        return
      }
      this.setChangeScheduleId(stationDeviceId, scheduleId, true)
    },

    stationScheduleChangeAll(isScheduleRun) {
      if(this.checkedStations.length === 0){
        alert(this.$t('station-alert-select'));
      }else{
        this.updateStationDeviceScheduleChangeList(this.checkedStations, isScheduleRun);
      }
    },
    stationScheduleChange(stationDeviceId, isScheduleRun){
      this.updateStationDeviceScheduleChange(stationDeviceId, isScheduleRun)
    },

    //endregion 스테이션 API ------------------------------------------------------------

    //region 영상 관련 기능 ------------------------------------------------------------
    getLiveDataForStation(idx) {
      let info = this.stationDeviceListMap[idx] || null
      if(info == null) {
        return null
      }
      if(info.device.managerData == null) {
        return null
      }
      return this.getLiveData(info.device.managerData.referenceDeviceId)
    },
    getLiveData(referenceDeviceId) {
      let findIndex = this.channelList.findIndex((find) => find.assignedDeviceId === referenceDeviceId)
      if(findIndex == -1) {
        return null
      }
      return this.channelList[findIndex]
    },
    playerLoadData(id, isLoad) {

      let findIndex = this.channelList.findIndex((channel)=> {
        return channel.channelId === id
      });
      if (findIndex != -1) {
        this.channelList[findIndex].isLoad = isLoad;
        this.$set(this.channelList, findIndex, this.channelList[findIndex]);
      }
    },
    async getChannelStationList() {
      var params = {
        eventId: this.eventId,
        assignedDeviceTypes : "Station",
        assignedStatus: "Assigned",
      };
      await fetchChannelList(params).then((res) => {
        if (res.data.result === 0) {
          let newChannelList = res.data.data.content;
          newChannelList.forEach((item) => {
            let findIndex = this.channelList.findIndex((find) => find.channelId === item.channelId)
            if(findIndex === -1) {
              item.isLoad = false;
              this.channelList.push(item)
            }
          });
        }
      });
    },
    //endregion 영상 관련 기능 ------------------------------------------------------------

    //region map 관련 기능 ------------------------------------------------------------
    initMapData(value) {
      if (value == 'en') {
        this.loadMap = 'google'
      } else {
        this.loadMap = 'kakao'
      }
      this.getEventPoint();

    },


    //endregion map 관련 기능 ------------------------------------------------------------

    getEvent() {
      if (this.eventId == undefined) {
        return;
      }
      fetchEvent(this.eventId).then((res) => {
        if (res.data.result == 0) {
          this.eventInfo = res.data.data;
          if (this.$refs.map == undefined) {
            return;
          }
          if (this.eventInfo.lng != null && this.eventInfo.lng != "" && this.eventInfo.lat != null && this.eventInfo.lat != "") {
            this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat)
            this.$refs.map.onCreateMapMarker(this.eventId, this.eventInfo.lng, this.eventInfo.lat, MapDataType.ID_SITE);
            this.$refs.map.onSetLngLat(this.eventInfo.lng, this.eventInfo.lat);
          } else {
            this.$refs.map.onAddressToMoveCenter(this.eventInfo.address);
          }
        }
      });
    },
    async getEventPoint() {
      let params = {
        eventId: this.eventId,
        serverTime: null,
      };
      await fetchPointList(params).then((res) => {
        if (res.data.result == 0) {
          this.pointServerTime = res.data.data.serverTime
          this.pointCount = res.data.data.total
          this.pointList = res.data.data.content
          this.pointList.forEach((element) => {
            element.selectId = element.index;
            element.selectType = "Point";
            element.isOwner = false;
            let pointMapMarker = new MapMarkerData();
            element.name = this.$t(getPointTypeName(element.type));
            pointMapMarker.setPointData(element);
            this.$refs.map.onCreateMarker(MapDataType.ID_POINT, pointMapMarker);
          })
        }
      });
    },

    customFormatter(date) {
      return moment(date).format("YYYY년 MM월 DD일");
    },

    onUpdateAiDetecting(selectAiDetecing) {
      //추후 ai서버와 연결 api메소드 추가
      selectAiDetecing;
    },

    toggleCheck(index) {
      const idx = this.checkedStations.indexOf(index);
      if (idx > -1) {
        // 이미 선택된 경우 선택 해제
        this.checkedStations.splice(idx, 1);
      } else {
        // 선택되지 않은 경우 선택
        this.checkedStations.push(index);
      }
    },
    selectAll() {
      this.stationDeviceList.forEach((item) => {
        this.checkedStations.push(item.device.stationDeviceId);
      });
    },
    deselectAll() {
      this.checkedStations = [];
    },

    stationOpen(stationDeviceId) {
      if(stationDeviceId != null) {
        stationPopup('stationControl', stationDeviceId)
      }
    },
  },
  beforeDestroy() {
    this.useConnectWebSocket.onWebSocketUnmounted();
    if(this.updateClockTimer != null) {
      clearInterval(this.updateClockTimer);
    }
    if(this.updateScheduleTimer != null) {
      clearInterval(this.updateScheduleTimer);
    }
  },

};
</script>
